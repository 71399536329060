const $hamburger = document.querySelector('.hamburger');
const $checkbox = document.querySelector('#nav-toggle');

document.addEventListener('click', e => {

  if (e.target.classList.contains('navigation-open')) {
    e.target.classList.remove('navigation-open');
    document.querySelector('body').classList.remove('no-scroll');

  } else if (e.target.classList.contains('hamburger')) {

    e.target.classList.add('navigation-open');
    document.querySelector('body').classList.add('no-scroll');
  }
})

document.addEventListener('DOMContentLoaded', function() {
  console.group('💕 from Flourish!');
  console.log('site by colin bonner');
  console.log('https://github.com/cfbonner');
})
