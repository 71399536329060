import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "banner" , "text" ]

  connect() {
    const root = document.documentElement;
    const elProperties = this.textTarget.getBoundingClientRect();
    const elWidth = elProperties.width;
    const bannerProperties = this.bannerTarget.getBoundingClientRect();
    const bannerWidth = bannerProperties.width;
    const elCount = Math.ceil(bannerWidth / (elWidth + 32));

    const textContent = this.textTarget.textContent;
    for (let i = 0; i <= elCount; i++) {
      const newSpan = document.createElement('span');
      newSpan.textContent = textContent;
      this.bannerTarget.appendChild(newSpan);
    }
    const speed = bannerWidth > 600 ? bannerWidth : 600;
    root.style.setProperty('--dynamic_animation', speed);
  }
}

