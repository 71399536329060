import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "input" , "label", "navigation" ]

  connect() {
    this.inputTarget.checked = false
    this.inputTarget.classList.remove('active')
    this.toggle()
  }

  toggle() {
    if (this.inputTarget.checked) {
      document.body.classList.add('navigation-open')
      this.inputTarget.classList.add('active')
    } else {
      document.body.classList.remove('navigation-open')
      this.inputTarget.classList.remove('active')
    }
  }

  close() {
    this.inputTarget.checked = false
    document.body.classList.remove('navigation-open')
    this.inputTarget.classList.remove('active')
  }
}

