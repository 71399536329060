const $icon_banner = document.querySelector('.icon-banner');

if ($icon_banner) {
  let spans = $icon_banner.querySelectorAll('span');
  spans.forEach(applyStyles)
}

function applyStyles(item, index) {
  item.classList.add(randomColor());
  item.classList.add(randomShape());
  item.classList.add(randomShadow());
  item.classList.add(randomDirection());
}

function randomColor() {
  switch (randomInteger(5)) {
    case 0:
      return 'orange'
      break;
    case 1:
      return 'pink'
      break;
    case 2:
      return 'red'
      break;
    case 3:
      return 'blue'
      break;
    case 4:
      return 'green'
      break;
    default:
      console.error('Invalid color');
  }
}

function randomShape() {
  switch(randomInteger(3)) {
    case 0:
      return 'circle'
      break;
    case 1:
      return 'square'
      break;
    case 2:
      return 'triangle'
      break;
    default:
      console.error('Invalid shape');
  }
}

function randomShadow() {
  switch (randomInteger(5)) {
    case 0:
      return 'orange-shadow'
      break;
    case 1:
      return 'pink-shadow'
      break;
    case 2:
      return 'red-shadow'
      break;
    case 3:
      return 'blue-shadow'
      break;
    case 4:
      return 'green-shadow'
      break;
    default:
      console.error('Invalid shadow');
  }
}

function randomDirection() {
  switch(randomInteger(2)) {
    case 0:
      return 'forwards'
      break;
    case 1:
      return 'backwards'
      break;
    default:
      console.error('Invalid direction');
  }
}

function randomInteger(maxValue) {
  return Math.floor(Math.random() * Math.floor(maxValue));
}

