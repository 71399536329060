import { Controller } from "stimulus";
import Swiper from "swiper/swiper-bundle.min.js";

export default class extends Controller {
  connect() {
    this.carousel = new Swiper(this.element, {
      slidesPerView: "auto",
      // loop: false,
      spaceBetween: 8,
      grabCursor: true,
      // autoplay: {
      //   delay: 1500,
      // },
      navigation: {
        nextEl: ".swiper-next",
        prevEl: ".swiper-prev",
      },
    });
  }
}
